import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { string, object } from 'yup'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import SimpleText from 'kiss/components/formik/simple-text'
import SubmitLoader from 'kiss/components/buttons/submit-loader'
import { LiftOff } from 'kiss/components/images/lift-off'
import trim from 'lodash/fp/trim'
import query from 'kiss/api/graphql-query'
import requestNewPasswordMutation from 'kiss/graphql/mutations/authenticate/request-new-password.graphql'
import { Button, pxToRem, Modal, Paragraph } from '@kisskissbankbank/kitten'

const requestNewPassword = (data) => (_dispatch, getState) => {
  return query(requestNewPasswordMutation, data, getState())
}

const ResetPasswordForm = ({ email: emailDefault }) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const [isSuccessMessageShown, showSuccessMessage] = useState(false)

  if (isSuccessMessageShown) {
    return (
      <>
        <Modal.Title>
          {t('authenticate.reset_password.reset_link_sent.title', {
            parseHtml: true,
          })}
        </Modal.Title>

        <Modal.Content aria-hidden>
          <LiftOff
            style={{
              maxHeight: '40vh',
              maxWidth: pxToRem(200),
              margin: '0 auto',
            }}
          />
        </Modal.Content>
      </>
    )
  }

  return (
    <>
      <Modal.Title>
        {t('authenticate.reset_password.send_reset_link.title', {
          parseHtml: true,
        })}
      </Modal.Title>

      <Modal.Content align="left">
        <Paragraph modifier="secondary">
          {t('authenticate.reset_password.send_reset_link.paragraph')}
        </Paragraph>

        <Formik
          initialValues={{ email: emailDefault }}
          validationSchema={object().shape({
            email: string()
              .required(t('login.form.email.error.missing'))
              .email(t('login.form.email.error.invalid')),
          })}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            dispatch(requestNewPassword({ email: trim(values.email) }))
              .then((response) => {
                setSubmitting(false)

                if (!response.requestNewPassword) {
                  setFieldError('email', t('login.form.email.error.exist'))
                } else {
                  showSuccessMessage(true)
                }
              })
              .catch(() => setSubmitting(false))
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} id="resetPasswordForm">
              <SimpleText
                name="email"
                type="email"
                normalize={(value) => trim(value)}
                aria-label="Email"
                id="resetPasswordEmailInput"
                className="k-u-margin-bottom-triple"
              />

              <Modal.Actions>
                {isSubmitting && <SubmitLoader fit="fluid" size="medium" />}
                {!isSubmitting && (
                  <Button
                    id="resetPasswordFormSubmit"
                    modifier="helium"
                    fit="fluid"
                    type="submit"
                  >
                    {t(
                      'authenticate.reset_password.send_reset_link.action_button',
                    )}
                  </Button>
                )}
              </Modal.Actions>
            </form>
          )}
        </Formik>
      </Modal.Content>
    </>
  )
}

ResetPasswordForm.propTypes = {
  email: PropTypes.string.isRequired,
}

export default ResetPasswordForm
