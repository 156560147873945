import { Autocomplete, LocationIcon } from '@kisskissbankbank/kitten'
import { useLazyQuery } from 'kiss/api/graphql-query'
import projectPlacesQuery from 'kiss/graphql/queries/project/places.graphql'
import { useTranslation } from 'kiss/hooks/use-translation'
import debounce from 'lodash/fp/debounce'
import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/fp/isEmpty'
import map from 'lodash/fp/map'
import prop from 'lodash/fp/prop'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

const getName = prop('name')

const getLocations = (data) =>
  flow(
    prop('places.nodes'),
    map((node) => getName(node)),
  )(data)

const ProjectsLocationAutocomplete = ({
  id,
  name,
  placeholder,
  onSelect,
  onKeyDown,
  defaultValue,
  size,
  className,
  iconPosition,
}) => {
  const t = useTranslation()
  const [shouldShowNoResultMessage, showNoResultMessage] = useState(false)
  const [places, setPlaces] = useState([])
  const [queryPlaces, { data, called, loading }] =
    useLazyQuery(projectPlacesQuery)

  const debouncedQueryPlaces = useCallback(
    debounce(200)((value) =>
      queryPlaces({
        variables: {
          startingWith: value,
        },
      }),
    ),
    [],
  )

  useEffect(() => {
    setPlaces(getLocations(data))
  }, [data])

  return (
    <Autocomplete
      className={className}
      id={id}
      name={name}
      placeholder={placeholder}
      items={places}
      isLoading={loading}
      size={size}
      updateSuggestionsStrategy={async ({ value }) => {
        if (!isEmpty(value)) {
          showNoResultMessage(true)
          await debouncedQueryPlaces(value)
          return places || []
        }
        return []
      }}
      icon={<LocationIcon />}
      iconPosition={iconPosition}
      noResultMessage={t('browsing.localisation.no_result')}
      shouldShowNoResultMessage={
        !loading && called && shouldShowNoResultMessage
      }
      onSelect={(e) => {
        setPlaces([])
        showNoResultMessage(false)
        onSelect(e)
      }}
      onKeyDown={(e) =>
        onKeyDown({
          keyCode: e.keyCode,
          value: e.target.value,
        })
      }
      defaultValue={defaultValue}
    />
  )
}

ProjectsLocationAutocomplete.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  onKeyDown: PropTypes.func,
  defaultValue: PropTypes.string,
}

ProjectsLocationAutocomplete.defaultProps = {
  id: 'project-location',
  name: 'project-location',
  placeholder: '',
  defaultValue: '',
  onSelect: () => {},
  onKeyDown: () => {},
  size: 'default',
  iconPosition: 'right',
}

export default ProjectsLocationAutocomplete
