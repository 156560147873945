import { Field, FlexWrapper } from '@kisskissbankbank/kitten'
import { useField, useFormikContext } from 'formik'
import Label from 'kiss/components/formik/label'
import Dropdown from 'kiss/components/formik/dropdown-select'
import { useTranslation } from 'kiss/hooks/use-translation'
import compact from 'lodash/fp/compact'
import find from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import includes from 'lodash/fp/includes'
import isEmpty from 'lodash/fp/isEmpty'
import isNull from 'lodash/fp/isNull'
import map from 'lodash/fp/map'
import slice from 'lodash/fp/slice'
import memoize from 'lodash/memoize'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

const getVariationFromValues = memoize(
  (value, variations) => {
    return find((variation) => {
      return flow(
        get('optionValues'),
        map((option) => {
          return !includes(option.id)(value)
        }),
        compact,
        isEmpty,
      )(variation)
    })(variations)
  },
  (value) => {
    return value.join('-')
  },
)
const NewVariations = ({ variationsOptions, variations }) => {
  const t = useTranslation()
  const [{ value: seletedOptionValueIds = [] }] = useField(
    'variationsOptionValueIds',
  )
  const [, meta, helpers] = useField('variation')
  const { setFieldValue } = useFormikContext()
  const selectedVariation = getVariationFromValues(
    seletedOptionValueIds,
    variations,
  )
  useEffect(() => {
    helpers.setValue(selectedVariation?.uuid)
  }, [selectedVariation])

  return (
    <div className="k-u-margin-bottom-triple">
      <FlexWrapper gap={20}>
        {variationsOptions.map((variationOption, index) => {
          return (
            <div key={variationOption.label}>
              <Label
                htmlFor={`variationsOptionValueIds[${index}]`}
                label={variationOption.label}
              />
              <Dropdown
                name={`variationsOptionValueIds[${index}]`}
                placeholder={t('project.modal.options.placeholder')}
                defaultSelectedValue={seletedOptionValueIds[index]}
                onChange={() => {
                  const isLastItem = index === variationsOptions.length - 1
                  const allChoicesSelected =
                    seletedOptionValueIds?.length === variationsOptions.length
                  if (!isLastItem && allChoicesSelected) {
                    setFieldValue(
                      `variationsOptionValueIds[${
                        variationsOptions.length - 1
                      }]`,
                      null,
                    )
                  }
                }}
                options={variationOption?.values.map(({ id, label }) => {
                  const isLastItem = index === variationsOptions.length - 1

                  const simpleLabelObject = {
                    label,
                    optionLabel: variationOption.label,
                    optionValueLabel: label,
                    value: id,
                  }

                  if (!isLastItem) {
                    return simpleLabelObject
                  }

                  const selectedOptionValuesIdsExceptLast = slice(0)(
                    variationsOptions.length - 1,
                  )(seletedOptionValueIds)

                  const variationFromLastOptionId = getVariationFromValues(
                    [...selectedOptionValuesIdsExceptLast, id],
                    variations,
                  )

                  if (!variationFromLastOptionId) {
                    return simpleLabelObject
                  }

                  const { totalQuantity, remainingQuantity } =
                    variationFromLastOptionId

                  if (isNull(totalQuantity) || isNull(remainingQuantity)) {
                    return simpleLabelObject
                  }

                  return {
                    label: `${label} - ${remainingQuantity}/${totalQuantity}`,
                    optionLabel: variationOption.label,
                    optionValueLabel: label,
                    value: id,
                    disabled: remainingQuantity <= 0,
                  }
                })}
              />
            </div>
          )
        })}
      </FlexWrapper>
      <div aria-live="polite">
        {meta.touched && meta.error && (
          <Field.ErrorMessage>
            {t('project.modal.options.error')}
          </Field.ErrorMessage>
        )}
      </div>
    </div>
  )
}

NewVariations.propTypes = { variations: PropTypes.array.isRequired }

export default NewVariations
