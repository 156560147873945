import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import isEmpty from 'lodash/fp/isEmpty'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getMentors } from 'kiss/modules/project-page/page-state/selectors'
import { pxToRem, Text } from '@kisskissbankbank/kitten'
import { getRouteFor, USER } from 'kiss/routes/redux'

const Wrapper = styled.div`
  display: grid;
  grid-gap: ${pxToRem(5)};
  grid-template-columns: repeat(3, 1fr);
  img {
    width: ${pxToRem(62)};
    height: ${pxToRem(62)};
    border-radius: var(--border-radius-s);
    object-fit: cover;
  }
`

const Mentors = () => {
  const t = useTranslation()
  const mentors = useSelector(getMentors)
  const routeFor = useSelector(getRouteFor)
  if (isEmpty(mentors)) return null
  return (
    <div>
      <Text
        tag="h3"
        size="micro"
        transform="uppercase"
        className="kiss-AsideTitle k-u-margin-top-none k-u-margin-bottom-noneHalf k-u-letter-spacing-10"
      >
        {t('permanent_project.social.mentors.title')}
      </Text>
      <Wrapper className="k-u-margin-top-single">
        {mentors.map(({ image, username, slug }) => {
          return (
            <a key={slug} href={routeFor(USER, { user: slug })}>
              <img alt={username} src={image.url} />
            </a>
          )
        })}
      </Wrapper>
    </div>
  )
}

export default Mentors
