import query from 'kiss/api/graphql-query'
import { getLocale } from 'kiss/app/redux'
import { createSelector } from '@reduxjs/toolkit'
import projectsRequest from 'kiss/graphql/queries/browsing/projects_list.graphql'
import getOr from 'lodash/fp/getOr'
import { ITEMS_PER_PAGE } from './context'

const NAME = 'BROWSING'

const LOADING_PROJECT_STATE = `${NAME}/LOADING_PROJECT_STATE`
const UPDATE_PROJECTS = `${NAME}/UPDATE_PROJECTS`

const initialState = {
  categories: [],
  projects: {},
  engagements: [],
  engagementsList: [],
  loading: false,
  totalProjects: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROJECTS:
    case LOADING_PROJECT_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export const fetchProjects = (searchParams) => (dispatch, getState) => {
  dispatch({ type: LOADING_PROJECT_STATE, payload: { loading: true } })
  const state = getState()
  const locale = getLocale(state)
  return query(
    projectsRequest,
    { ...searchParams, locale, itemsPerPage: ITEMS_PER_PAGE },
    state,
  )
    .then((result, error) => {
      if (!error) {
        const projects = getOr([])('projects')(result)
        const engagements = getOr([])('engagements')(result)

        dispatch({
          type: UPDATE_PROJECTS,
          payload: {
            projects,
            engagements,
            totalCount: getOr(0)('projects.totalCount')(result),
          },
        })
      }
    })
    .finally(() => {
      dispatch({ type: LOADING_PROJECT_STATE, payload: { loading: false } })
    })
}

export const getCategories = (state) => state[NAME].categories || []

export const getEngagementsList = (state) => state[NAME].engagementsList || []

export const getProjects = createSelector(
  (state) => state[NAME]?.projects?.edges ?? [],
  (edges) => edges.map(({ node }) => node),
)

export const getFilteredTotalProjects = (state) =>
  state[NAME].projects?.totalCount

export const getTotalProjects = (state) => {
  return state[NAME].totalProjects?.totalCount
}
export const isLoading = (state) => {
  return state[NAME].loading
}

export default { [NAME]: reducer }
