import React from 'react'

import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'

import { useTranslation } from 'kiss/hooks/use-translation'

import styled from 'styled-components'

import {
  pxToRem,
  Text,
  CONTAINER_PADDING,
  CONTAINER_PADDING_THIN,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import { getProjectSlug } from 'kiss/modules/contribute/redux'
import {
  CART,
  getRouteFor,
  THANK_YOU,
  CARD_PAYMENT_FAILURE,
} from 'kiss/routes/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'

import useContributeStepper from 'kiss/modules/contribute/hooks/use-stepper'
import Steps from 'kiss/modules/contribute/components/steps'
import ProgressBar from 'kiss/modules/contribute/components/progress-bar'

import getOr from 'lodash/fp/getOr'

const StyledSubHeader = styled.div`
  position: relative;
  z-index: 3;
  background-color: var(--color-grey-200);

  .kiss-SubHeader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: ${pxToRem(80)};
    text-align: center;
  }

  .kiss-SubHeader__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    --subHeader-arrow-margin: ${pxToRem(CONTAINER_PADDING_THIN)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      --subHeader-arrow-margin: ${pxToRem(CONTAINER_PADDING)};
    }
  }

  .kiss-SubHeader__back {
    left: 0;
    padding-left: var(--subHeader-arrow-margin);
  }
  .kiss-SubHeader__next {
    right: 0;
    padding-right: var(--subHeader-arrow-margin);
  }

  .k-Meters-Progress {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;

    .k-Meters-Progress__ramp {
      height: ${pxToRem(4)};
      background-color: transparent;

      &::after {
        background-color: var(--color-grey-900);
      }
    }
  }

  .kiss-SubHeader__step {
    letter-spacing: ${pxToRem(1)};
  }
`

const SubHeader = (props) => {
  const t = useTranslation()

  const history = useHistory()

  const { prevRoute, wording } = useContributeStepper()

  const routeFor = useSelector(getRouteFor)
  const projectSlug = useSelector(getProjectSlug)

  const prevHref = routeFor(prevRoute, { project: projectSlug })

  const isMatchCart = getOr(false)('isExact')(useRouteMatch(CART))

  const isMatchThankYou = getOr(false)('isExact')(useRouteMatch(THANK_YOU))
  const isMatchFailure = getOr(false)('isExact')(
    useRouteMatch(CARD_PAYMENT_FAILURE),
  )

  const handlePreviousPage = (e) => {
    e.preventDefault()

    if (isMatchCart) {
      RoutingHelper.redirect(prevHref)
    } else {
      history.push(prevHref)
    }
  }

  return (
    <StyledSubHeader {...props}>
      <div className="kiss-SubHeader">
        {!(isMatchThankYou || isMatchFailure) && (
          <Text
            color="font1"
            weight="500"
            tag="a"
            href={prevHref}
            onClick={handlePreviousPage}
            title={t('app.action.back')}
            aria-label={t('app.action.back')}
            className="k-u-link kiss-SubHeader__arrow kiss-SubHeader__back"
          >
            <span className="k-u-margin-right-single" aria-hidden>
              ←
            </span>
            <span className="k-u-hidden@m-down">{wording.previous}</span>
          </Text>
        )}

        <Steps />

        {wording.next && (
          <Text
            color="font2"
            weight="500"
            title={t('app.action.next')}
            className="k-u-link kiss-SubHeader__arrow kiss-SubHeader__next"
          >
            <span className="k-u-hidden@m-down">{wording.next}</span>
            <span className="k-u-margin-left-single" aria-hidden>
              →
            </span>
          </Text>
        )}

        <ProgressBar />
      </div>
    </StyledSubHeader>
  )
}

export default SubHeader
