import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/fp/isEmpty'
import Form from './form'
import Comment from './comment'
import { useTranslation } from 'kiss/hooks/use-translation'

const Replies = ({
  replies,
  commentUuid,
  isShowingForm,
  hideForm,
  replyOnClick,
  closeForm,
}) => {
  const t = useTranslation()

  if (isEmpty(replies) && !isShowingForm) return null

  return (
    <>
      {replies.map((props) => (
        <Comment
          key={`${props.uuid}-${props.text}`}
          className="k-u-margin-top-noneHalf"
          isSecondary
          parentUuid={commentUuid}
          replyOnClick={replyOnClick}
          {...props}
        />
      ))}

      {isShowingForm && (
        <Form
          className="k-u-margin-top-noneHalf"
          id={`reply-form-${commentUuid}`}
          commentButtonLabel={t('project.comments.reply')}
          commentUuid={commentUuid}
          onSubmit={hideForm}
          onClose={closeForm}
        />
      )}
    </>
  )
}

Replies.propTypes = {
  replies: PropTypes.array,
  commentUuid: PropTypes.string,
  isShowingForm: PropTypes.bool,
  replyOnClick: PropTypes.func,
  closeForm: PropTypes.func,
}

Replies.defaultProps = {
  replies: [],
  commentUuid: null,
  isShowingForm: false,
  replyOnClick: () => {},
  closeForm: undefined,
}

export default Replies
