import config from 'kiss/config'
import uniq from 'lodash/fp/uniq'
import { isGif, isSvg } from '../validators'

const ALLOWED_EXTENSIONS = ['jpg', 'jpeg', 'png', 'webp']

export const isServerAllowed = (path) => {
  const server = path.replace(/https?:\/\/([0-9a-z.:]+)\/\S+/gi, '$1')
  return (
    config[APP_ENV].responsiveImageHandler.allowedServers.indexOf(server) > -1
  )
}

export const assetUrl = (assetPath) => {
  const path = encodeURIComponent(encodeURIComponent(assetPath))

  return path
}

export const urlBuilder = (
  path,
  { width = 0, height = 0, filters = [], upscale = true },
) => {
  if (isSvg(path) || isGif(path) || !ENABLE_RESPONSIVE_IMAGES) {
    return path
  }

  const imageUrl = assetUrl(path)
  const env = APP_ENV
  const server = config[env].responsiveImageHandler.host
  const hostAndSize = `${server}/resized/${width}x${height}`

  if (!upscale) {
    filters.push('no_upscale:1')
  }

  let extension = ''
  filters.forEach((filter) => {
    const [filterKey, filterValue] = filter.split(':')

    if (filterKey === 'format' && ALLOWED_EXTENSIONS.includes(filterValue)) {
      extension = `.${filterValue}`
    }
  })

  const chainedFilters = uniq(filters).join(',')

  if (!chainedFilters) return `${hostAndSize}/options:false/${imageUrl}`

  return `${hostAndSize}/${chainedFilters}/${imageUrl}${extension}`
}
