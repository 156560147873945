import { gql } from '@apollo/client'
import has from 'lodash/fp/has'
import { useMutation } from '../../api/graphql-query'

const REGISTER_CARD_MUTATION = gql`
  mutation RegisterCard($giftCardId: String, $cardType: String!) {
    registerCard(giftCardId: $giftCardId, cardType: $cardType) {
      accessKey
      registrationUrl
      preregistrationData
      uuid
    }
  }
`

const useRegisterGiftCard = () => {
  const [regsiterCard] = useMutation(REGISTER_CARD_MUTATION)
  return {
    callRegisterGiftCard: async ({ giftCardId, cardType }) => {
      const response = await regsiterCard({
        variables: { giftCardId, cardType },
      })
      const createCardRegistration = response.data.registerCard
      if (!response || has('errors')(createCardRegistration)) {
        throw new Error('callRegisterCard failed')
      }
      return createCardRegistration
    },
  }
}

export default useRegisterGiftCard
